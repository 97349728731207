//@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;1,400;1,500&family=Mulish:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

@font-face {
	font-family: 'icomoon';
	src: url('..//fonts/icomoon.woff2?tcuos2') format('woff2');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

/* lora-regular - latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Lora';
	font-style: normal;
	font-weight: 400;
	src: url('..//fonts/lora-v32-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* lora-italic - latin */
  @font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Lora';
	font-style: italic;
	font-weight: 400;
	src: url('..//fonts/lora-v32-latin-italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* lora-500 - latin */
  @font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Lora';
	font-style: normal;
	font-weight: 500;
	src: url('..//fonts/lora-v32-latin-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* lora-500italic - latin */
  @font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Lora';
	font-style: italic;
	font-weight: 500;
	src: url('..//fonts/lora-v32-latin-500italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }

  /* mulish-regular - latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Mulish';
	font-style: normal;
	font-weight: 400;
	src: url('..//fonts/mulish-v13-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* mulish-italic - latin */
  @font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Mulish';
	font-style: italic;
	font-weight: 400;
	src: url('..//fonts/mulish-v13-latin-italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* mulish-500 - latin */
  @font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Mulish';
	font-style: normal;
	font-weight: 500;
	src: url('..//fonts/mulish-v13-latin-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* mulish-500italic - latin */
  @font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Mulish';
	font-style: italic;
	font-weight: 500;
	src: url('..//fonts/mulish-v13-latin-500italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* mulish-700 - latin */
  @font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Mulish';
	font-style: normal;
	font-weight: 700;
	src: url('..//fonts/mulish-v13-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* mulish-700italic - latin */
  @font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Mulish';
	font-style: italic;
	font-weight: 700;
	src: url('..//fonts/mulish-v13-latin-700italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }

$primaryFont: 'Mulish';
$secondaryFont: 'Lora';
$iconFont: 'icomoon';

$white: #fff;
$dawn: #fbb03b;
$iceBlue: #ebf7f6;
$glacier: #3aafa9;
$glacierSecondary: #319691;
$teal: #2b7a78;
$black: #17242a;
$offWhite: #feffff;
$ice: #def2fa;
$cream: #fff4e3;
$gray: #18242a;
$lightBlue: #e1f1f1;
$red: #c0111f;

/*! purgecss start ignore */
@mixin transition($property: all, $duration: 0.4s, $easing: ease-in-out) {
  -webkit-transition: $property $duration $easing;
  -moz-transition: $property $duration $easing;
  -ms-transition: $property $duration $easing;
  -o-transition: $property $duration $easing;
  transition: $property $duration $easing;
}

@mixin transform($transforms) {
	   -moz-transform: $transforms;
	     -o-transform: $transforms;
	    -ms-transform: $transforms;
	-webkit-transform: $transforms;
          transform: $transforms;
}

 @mixin rotate($degrees) {
	-webkit-transform: rotate($degrees);
	-moz-transform: rotate($degrees);
	-ms-transform: rotate($degrees);
	-o-transform: rotate($degrees);
	transform: rotate($degrees);
}

@mixin prefixer($property, $value, $prefixes: webkit) {
    @each $prefix in $prefixes {
        #{"-" + $prefix + "-" + $property}: $value;
    }
    #{$property}: $value;
}

@mixin translate ($x, $y) {
   @include transform(translate($x, $y));
}

@mixin align-center{
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	-moz-align-items: center;
	align-items: center;
	align-content: center;
}

@mixin button{
	display: inline-block;
	text-align: center;
	border: none;
	text-decoration: none;
}

@mixin green-button{
	@include button;
	background: $teal;
	border-radius: 30px;
	color: $white;
	font-weight: 500;
	padding: 9px 20px;
	min-width: 180px;
	border: 1px solid $teal;
	&:hover{
		background: $glacierSecondary;
		border: 1px solid $glacierSecondary;
		.icon-arrow{
			left: 5px;
			@include transition(all, 300ms, ease-in-out);
		}
	}
	.icon-arrow{
		left: 0;
		top: -1px;
		font-size: 16px;
		position: relative;
		margin-right: 10px;
		display: inline-block;
		vertical-align: middle;
		@include transition(all, 300ms, ease-in-out);
		&:before{
			content: '\e901';
		}
	}
}

@mixin white-button{
	@include button;
	background: $white;
	border-radius: 30px;
	color: $teal;
	font-weight: 500;
	padding: 9px 20px;
	min-width: 180px;
	&:hover{
		.icon-arrow{
			left: 5px;
			@include transition(left, 300ms, ease-in-out);
		}
	}
	.icon-arrow{
		left: 0;
		top: -1px;
		font-size: 16px;
		position: relative;
		margin-right: 10px;
		display: inline-block;
		vertical-align: middle;
		@include transition(left, 300ms, ease-in-out);
		&:before{
			content: '\e901';
		}
	}
}

@mixin green-arrow-button{
	@include button;
	color: $teal;
	font-weight: 500;
	padding-left: 10px;
	&:hover{
		color: $teal;
		.icon-arrow{
			left: 5px;
			@include transition(left, 300ms, ease-in-out);
		}
	}
	.icon-arrow{
		left: 0;
		top: -1px;
		font-size: 16px;
		position: relative;
		margin-right: 10px;
		display: inline-block;
		vertical-align: middle;
		@include transition(left, 300ms, ease-in-out);
		&:before{
			content: '\e901';
		}
	}
}
/*! purgecss end ignore */

/*
	Body & HTML setup
	
*/

#full-height{
	display: none;
}

body, html {
	height:100%;	
}

body {
    font-family: $primaryFont;
	font-size: 18px;
	color: $black;
	&.nav-menu-open{
		position: fixed;
		overflow: hidden;
		left: 0px;
		right: 0px;		
	}	
}

.container {
    position: relative;
	margin-left: auto;
	margin-right: auto;
	*zoom: 1;
	max-width:1170px;
	min-width: 0px;
	width: 100%;
	padding: 0;	
	&.large-container{
		max-width: 970px;
	}
	&.xsmall-container{
		max-width: 770px;
	}
	&.xsmall-container-alt{
		max-width: 830px;
	}
	&.xlarge-container{
		max-width: 1380px;
	}
}

.container-fluid{
    position: relative;
	margin-left: auto;
	margin-right: auto;
	*zoom: 1;
	width: 100%;
	max-width:2000px;
	min-width: 320px;	
	padding: 0;		
}

.wrap {
	position: relative;
	margin: 0 auto -90px; /* bottom is negative value of .push height */
	min-height: 100%;
	height: auto !important;
	height: 100%;
	max-width: 2000px;
}
.push {
	height: 90px;
	background: transparent;
} /* footer height plus padding */

.padding
{
	padding-left: 20px !important;
	padding-right: 20px !important;
}

.nomargin{
	margin-left: 0px !important;
	margin-right: 0px !important;
}

::-moz-selection { /* Code for Firefox */
    color: white;
    background: $teal;
}

::selection {
    color: white; 
    background: $teal;
}

:focus
{
	//outline: 0px none transparent;
}

::-moz-focus-inner {
    border: 0;
    padding: 0;
}

::-webkit-input-placeholder {
	color: rgba(23, 36, 42, 0.5);
}

:-moz-placeholder { /* Firefox 18- */
	color: rgba(23, 36, 42, 0.5);
   	opacity: 1;
}

::-moz-placeholder {  /* Firefox 19+ */
   color: rgba(23, 36, 42, 0.5);
   opacity: 1;
}

:-ms-input-placeholder {  
	color: rgba(23, 36, 42, 0.5);
}

h1, h2, h3, h4, h5, h6, p{
     margin-top: 0px;
     margin-bottom: 0px;
}
input[type="text"], input[type="email"], input[type="password"], input[type="tel"], input[type="submit"], textarea, select{
	-webkit-appearance: none;
	border-radius: 0px;
}

#preload * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
}

a, input[type="submit"], button{
	@include transition(all, 300ms, ease-in-out);	
	&:hover{
		@include transition(all, 300ms, ease-in-out);
	}
}
img{
	max-width: 100%;
	&.alignright{
		float: right; 
		margin: 0 0 1em 1em;
	}
	&.alignleft{
		float: left; 
		margin: 0 1em 1em 0;
	}
	&.aligncenter{
		display: block; 
		margin-left: auto; 
		margin-right: auto;
	}
}
.alignright{ 
	float: right; 
}
.alignleft{
	float: left; 
}
.aligncenter{ 
	display: block;
	margin-left: auto; 
	margin-right: auto; 
}

img{
	max-width: 100%;
	&:not([src]) {
    	visibility: hidden;
	}
	&.alignright{
		float: right; 
		margin: 0 0 1em 1em;
	}
	&.alignleft{
		float: left; 
		margin: 0 1em 1em 0;
	}
	&.aligncenter{
		display: block; 
		margin-left: auto; 
		margin-right: auto;
	}
}
.alignright{ 
	float: right; 
}
.alignleft{
	float: left; 
}
.aligncenter{ 
	display: block;
	margin-left: auto; 
	margin-right: auto; 
}

@media screen and (max-width: 767px){
	img{
		&.alignright, &.alignleft{
			float: none;
			display: block;
			margin: 0 auto 30px;
		}
	}
	.alignright, .alignleft{
		float: none;
		margin: 0 auto 30px;
	}
}

[class^="icon-"], [class*=" icon-"] {
	font-family: $iconFont !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.animsition-loading{
	border: none;
    -webkit-animation-iteration-count: none;
    animation-iteration-count: none;
    -webkit-animation-timing-function: none;
    animation-timing-function: none;
    -webkit-animation-duration: 0s;
    animation-duration: 0s;
    -webkit-animation-name: none;
    animation-name: none;	
    width: 60px;
}

@media screen and (max-width: 767px){
    .animatedParent .animated {
        @include prefixer(transition-property, none !important);
        @include prefixer(animation, none !important);
        @include transform(none !important);
        opacity: 1
    }	
}