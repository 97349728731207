footer{
    #minimal-footer-container{
        background: $black;
        padding: 100px 0;
        position: relative;
        &:after{
            position: absolute;
            content: '';
            right: 80px;
            bottom: 0;
            background-image: url('../images/footer-feather.svg');
            background-size: cover;
            background-position: top center;
            width: 400px;
            height: 350px;
            opacity: 0.1;
        }
        >div{
            >div{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                @include align-center;
                >div{
                    &:first-child{
                        width: calc(100% - 250px);
                        padding-right: 150px;
                        >div{
                            margin-bottom: 10px;
                            a, p{
                                display: inline-block;
                                color: $white;
                            }
                            a{
                                margin-left: 15px;
                                text-decoration: none;
                                opacity: 1;
                                &:hover{
                                    opacity: 0.7;
                                }
                                .icon-cacpro{
                                    position: relative;
                                    top: 1px;
                                    margin-left: 5px;
                                    &:before{
                                        content: '\e900';
                                    }
                                }
                            }
                        }
                        p{
                            &.disclaimer{
                                font-size: 12px;
                                line-height: 1.7;
                                color: $white;
                            }
                        }
                    }
                    &:last-child{
                        width: 250px;
                        ul{
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            padding: 0;
                            margin: 0;
                            list-style: none;
                            position: relative;
                            z-index: 10000;
                            >li{
                                width: 100%;
                                margin-bottom: 20px;
                                a{
                                    color: $white;
                                    text-decoration: none;
                                    &:hover{
                                        .icon-arrow{
                                            left: 5px;
                                            @include transition(all, 300ms, ease-in-out);
                                        }
                                    }
                                    .icon-arrow{
                                        left: 0;
                                        position: relative;
                                        margin-right: 15px;
                                        display: inline-block;
                                        vertical-align: middle;
                                        @include transition(all, 300ms, ease-in-out);
                                        &:before{
                                            content: '\e901';
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    #top-footer-container{
        background: $black;
        padding: 70px 0;
        position: relative;
        &:after{
            position: absolute;
            content: '';
            right: 80px;
            bottom: 0;
            background-image: url('../images/footer-feather.svg');
            background-size: cover;
            background-position: top center;
            width: 400px;
            height: 350px;
            opacity: 0.1;
        }
        >div{
            >div{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                position: relative;
                z-index: 1000;
                >div{
                    &:first-child{
                        width: 35%;
                        p{
                            color: $white;
                        }
                        a{
                            color: $glacier;
                            opacity: 1;
                            text-decoration: none;
                            &:hover{
                                opacity: 0.7;
                            }
                        }
                        p{
                            line-height: 1.667;
                            margin-bottom: 30px;
                        }
                        #top-footer-social-container{
                            a{
                                display: inline-block;
                                vertical-align: middle;
                                color: $white;
                                font-size: 26px;
                                text-decoration: none;
                                &:not(:last-of-type){
                                    margin-right: 20px;
                                }
                                .icon-facebook{
                                    font-size: 28px;
                                    &:before{
                                        content: '\e908';
                                    }
                                }
                                .icon-linkedin{
                                    &:before{
                                        content: '\e907';
                                    }
                                }
                            }
                        }
                    }
                    &:last-child{
                        width: 65%;
                        >div{
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            >div{
                                width: 40%;
                                &:nth-of-type(2n){
                                    margin-left: 5%;
                                }
                            }
                        }
                        ul{
                            padding: 0;
                            margin: 0;
                            list-style: none;
                            >li{
                                width: 100%;
                                margin-bottom: 20px;
                                a{
                                    color: $white;
                                    text-decoration: none;
                                    &:hover{
                                        .icon-arrow{
                                            left: 5px;
                                            @include transition(all, 300ms, ease-in-out);
                                        }
                                    }
                                    .icon-arrow{
                                        left: 0;
                                        position: relative;
                                        margin-right: 15px;
                                        display: inline-block;
                                        vertical-align: middle;
                                        @include transition(all, 300ms, ease-in-out);
                                        &:before{
                                            content: '\e901';
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    #bottom-footer-container{
        padding: 30px 0;
        >div{
            >div{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                @include align-center;
                >div{
                    &:first-child{
                        width: 60%;
                        >div{
                            margin-bottom: 10px;
                            a, p{
                                display: inline-block;
                            }
                            a{
                                margin-left: 15px;
                                color: $black;
                                text-decoration: none;
                                opacity: 1;
                                &:hover{
                                    opacity: 0.7;
                                }
                                .icon-cacpro{
                                    position: relative;
                                    top: 1px;
                                    margin-left: 5px;
                                    &:before{
                                        content: '\e900';
                                    }
                                }
                            }
                        }
                        p{
                            &.disclaimer{
                                font-size: 12px;
                                line-height: 1.7;
                            }
                        }
                    }
                    &:last-child{
                        width: 40%;
                        >div{
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            justify-content: flex-end;
                            @include align-center;
                            >div{
                                width: 100%;
                                max-width: 80px;
                                &:not(:first-of-type){
                                    margin-left: 15px;
                                }
                                a{
                                    opacity: 1;
                                    &:hover{
                                        opacity: 0.7;
                                    }
                                }
                            }
                        }
                        p{
                            &.mobile-disclaimer{
                                display: none;
                                font-size: 12px;
                                line-height: 1.7;
                                margin-top: 20px;
                                text-align: left;
                            }
                        }
                    }
                }
            }
        }
    }
    #gtag-footer{
        display: none;
        visibility: hidden;
    }
}

@media screen and (max-width: 1210px){
    footer{
        #minimal-footer-container{
            padding: 100px 20px;
        }
        #top-footer-container{
            padding: 70px 20px;
            &:after{
                right: 10px;
            }
        }
        #bottom-footer-container{
            padding: 30px 20px;
        }
    }
}

@media screen and (max-width: 1000px){
    footer{
        #minimal-footer-container{
            >div{
                >div{
                    >div{
                        &:first-child, &:last-child{
                            width: 100%;
                        }
                        &:first-child{
                            margin-bottom: 30px;
                            padding-right: 0;
                        }
                    }
                }
            }
        }
        #top-footer-container{
            >div{
                >div{
                    >div{
                        &:last-child{
                            >div{
                                >div{
                                    width: 48%;
                                    &:nth-of-type(2n){
                                        margin-left: 4%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        #bottom-footer-container{
            >div{
                >div{
                    >div{
                        &:first-child{
                            width: 100%;
                            text-align: center;
                            >div{
                                a{
                                    margin-left: 0;
                                }
                                p{
                                    display: block;
                                    margin-bottom: 5px;
                                }
                            }
                        }
                        &:last-child{
                            width: 100%;
                            margin-top: 40px;
                            >div{
                                justify-content: center;
                                >div{
                                    padding: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px){
    footer{
        #minimal-footer-container{
            padding: 50px 20px;
            >div{
                >div{
                    flex-direction: column-reverse;
                    >div{
                        &:first-child{
                            margin: 20px 0 0;
                            text-align: center;
                            >div{
                                p, a{
                                    display: block;
                                }
                                a{
                                    margin: 10px 0 0;
                                }
                            }
                        }
                    }
                }
            }
        }
        #top-footer-container{
            padding: 50px 20px 40px;
            overflow: hidden;
            &:after{
                right: -40px;
                width: 320px;
                height: 370px;
            }
            >div{
                >div{
                    >div{
                        &:first-child, &:last-child{
                            width: 100%;
                        }
                        &:last-child{
                            padding: 50px 0 0;
                            >div{
                                >div{
                                    width: 100%;
                                    &:nth-of-type(2n){
                                        margin-left: 0;
                                    }
                                }
                            }
                            ul{
                                position: relative;
                                z-index: 10000;
                                li{
                                    width: 100%;
                                    &:nth-of-type(2n){
                                        margin-left: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        #bottom-footer-container{
            >div{
                >div{
                    >div{
                        &:first-child, &:last-child{
                            width: 100%;
                        }
                        &:first-child{
                            >div{
                                p{
                                    margin-bottom: 10px;
                                }
                            }
                            .disclaimer{
                                display: none;
                            }
                        }
                        &:last-child{
                            margin-top: 20px;
                            p{
                                &.mobile-disclaimer{
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}