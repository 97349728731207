/*! purgecss start ignore */
#page-builder-container{
	&.minimal-page{
		padding: 80px 20px;
	}
	.content-row{
		&.accordions-type{
			padding: 50px 0;
			>div{
				&:last-child{
					margin-top: 40px;
				}
			}
			.accordion{
				border-bottom: 1px solid rgba(43, 122, 120, 0.2);
				.accordion-header{
					display: inline-block;
					width: 100%;
					padding: 20px 40px 20px 0;
					color: $black;
					position: relative;
					text-decoration: none;
					&.active{
						color: $teal;
						&:after{
							@include rotate(180deg);
							transition: all 0.3s ease-out;
						}
					}
					&:after{
						position: absolute;
						content: '\e90f';
						font-family: $iconFont;
						top: 27px;
						right: 0;
						font-size: 16px;
						color: $teal;
						transition: all 0.3s ease-out;
					}
					h3{
						margin-bottom: 0;
					}
				}
				.accordion-content{
					max-height: 0;
                    overflow: hidden;
                    transition: all 0.5s ease-out;
                    padding: 0 60px 0 0;
                    &.active{
	                    max-height: 2800px;
                        transition: all 0.5s ease-in;
                    }
				}
			}
		}
		&.blog-full-type{
			padding: 80px 0;
			background: $iceBlue;
			>div{
				>div{
					&:first-child{
						text-align: center;
						margin-bottom: 40px;
					}
					.blog-list-item{
			            display: flex;
			            flex-direction: row;
			            flex-wrap: wrap;
			            margin-bottom: 15px;
			            padding: 25px;
			            border-radius: 10px;
			            background: $iceBlue;
			            @include transition(all, 300ms, ease-in-out);
			            &:hover{
			                background: $white;
			                @include transition(all, 300ms, ease-in-out);
			            }
			            >div{
			                &:first-child{
			                    width: 200px;
			                    img{
			                        border-radius: 10px;
			                    }
			                }
			                &:last-child{
			                    width: calc(100% - 200px);
			                    padding-left: 30px;
								text-align: left;
			                    h3{
			                        margin-bottom: 10px;
			                    }
								a{
									color: $black;
									&:hover{
										color: $teal;
									}
								}
			                    .blog-list-details{
			                        margin-bottom: 20px;
									a{
										color: $teal;
									}
			                        span{
			                            display: inline-block;
			                            margin: 0 5px;
			                            position: relative;
			                            top: -1px;
			                            color: $black;
										&.sep{
											margin: 0;
										}
			                        }
			                        p{
			                            margin-bottom: 0;
			                            color: $black;
										display: inline-block;
			                        }
									div{
										display: inline;
										a{
											&:last-child{
												span{
													display: none;
												}
											}
										}
									}
									.excerpt{
										display: block;
									}
			                    }
			                    .button{
				                    margin-top: 10px;
			                    }
			                    .excerpt{
				                    margin-top: 10px;
				                    line-height: 1.667;
			                    }
			                }
			            }
			        }
				}
			}
		}
		&.call-to-action-type{
			padding: 20px 0;
			>div{
				&.image{
					border-radius: 10px;
					position: relative;
					text-align: center;
					padding: 120px 40px;
					background-size: cover;
					background-position: 50%;
					&:after{
			            position: absolute;
			            content: '';
			            top: 0;
			            left: 0;
			            border-radius: 10px;
			            display: inline-block;
			            background: rgba(23, 36, 42, 0.5);
			            width: 100%;
			            height: 100%;
			        }
			        h1, h2, h3,h4, h5, h6, p{
				        color: $white;
				        position: relative;
				        z-index: 100;
			        }
			        .button{
				        position: relative;
				        z-index: 100;
				        margin-top: 20px;
			        }
				}
				&.no-image{
					background: $teal;
					border-radius: 10px;
					position: relative;
					text-align: center;
					padding: 120px 40px;
					&:after{
			            position: absolute;
			            content: '';
			            right: 0px;
			            bottom: 0;
			            background-image: url('../images/footer-feather.svg');
			            background-size: cover;
			            background-position: top center;
			            width: 300px;
			            height: 250px;
			            opacity: 0.1;
			        }
			        h1, h2, h3,h4, h5, h6, p{
				        color: $white;
				        position: relative;
				        z-index: 100;
			        }
					a:not(.button){
						color: $white;
					}
			        .button{
				        position: relative;
				        z-index: 100;
				        margin-top: 20px;
                        &:hover{
                            border: 1px solid $teal;
                        }
			        }
				}
			}
		}
		&.card-widgets-type{
			padding: 80px 0;
			>div{
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: center;
				>div{
					background: $iceBlue;
					border-bottom-left-radius: 10px;
					border-bottom-right-radius: 10px;
					.title{
						background: $teal;
						border-top-left-radius: 10px;
						border-top-right-radius: 10px;
						text-align: center;
						padding: 20px;
						min-height: 120px;
						h3{
							margin: 0;
							color: $white;
						}
					}
					.content{
						padding: 20px;
						text-align: center;
						display: flex;
						flex-direction: column;
						height: calc(100% - 120px);
						.button{
							margin-top: auto;
							align-self: center;
						}
					}
				}
			}
		}
		&.carousel-type{
			padding: 90px 0;
			&.white-color{
				background: $white;
			}
			&.green-color{
				background: $iceBlue;
			}
			.swiper-slide{
				padding: 0 60px;
				>div{
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					width: 100%;
					@include align-center;
					>div{
						&:first-child{
							width: 65%;
							padding-right: 60px;
						}
						&:last-child{
							width: 35%;
							img{
								border-radius: 10px;
								width: 100%;
							}
						}
					}
				}
			}
			.swiper-button-prev{
				width: 40px;
				height: 40px;
				border-radius: 50%;
				background: $teal;
				left: 0px;
				transform: translateY(-50%);
				top: 50%;
				&:hover{
					background: $teal;
				}
				&:after{
					content: '\e901';
					font-family: $iconFont;
					color: $white;
					display: inline-block;
					@include rotate(180deg);
					font-size: 	12px;
				}
			}
			.swiper-button-next{
				width: 40px;
				height: 40px;
				border-radius: 50%;
				background: $teal;
				right: 0px;
				transform: translateY(-50%);
				top: 50%;
				&:hover{
					background: $teal;
				}
				&:after{
					content: '\e901';
					font-family: $iconFont;
					color: $white;
					font-size: 14px;
				}
			}
			.swiper-pagination{
				position: relative;
				margin-top: 50px;
				.swiper-pagination-bullet{
					border-radius: 50%;
					border: 1px solid $teal;
					background: transparent;
					width: 12px;
					height: 12px;
					opacity: 1;
					@include transition(all, 300ms, ease-in-out);
					&:hover, &.swiper-pagination-bullet-active{
						background: $teal;
						@include transition(all, 300ms, ease-in-out);
					}
				}
			}
		}
		&.content-type{
			padding: 30px 0;
			&.green-color{
				background: $iceBlue;
				padding: 60px 0;
			}
			p{
				margin-bottom: 20px;
			}
		}
		&.fact-sheet-type{
			padding: 80px 0 55px;
			>div{
				&:first-child{
					text-align: center;
					margin-bottom: 60px;
				}
				&:last-child{
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					justify-content: center;
					>div{
	                    padding: 25px;
	                    border-radius: 10px;
	                    background: $iceBlue;
	                    text-align: center;
	                    @include transition(all, 300ms, ease-in-out);
						margin-bottom: 25px;
	                    &:hover{
	                        background: rgba(58, 175, 169, 0.2);
	                        @include transition(all, 300ms, ease-in-out);
	                    }
                        img{
	                        margin-bottom: 20px;
	                        border-radius: 10px;
                        }
                        p{
	                        margin-bottom: 10px;
                        }
					}
				}
			}
		}
		&.form-type{
			padding: 50px 0;
		}
		&.header-type{
			background: $iceBlue;
			padding: 100px 0 0;
			margin-bottom: 170px;
			img{
				width: 100%;
				border-radius: 10px;
				top: 100px;
				margin-top: -80px;
				position: relative;
				height: 470px;
				object-fit: cover;
			}
		}
		&.image-widgets-type{
			padding: 80px 0;
			background: $iceBlue;
			margin-bottom: 60px;
			>div{
				>div{
					&:first-child{
						text-align: center;
						margin-bottom: 40px;
					}
					&:last-child{
						display: flex;
						flex-direction: row;
						flex-wrap: wrap;
						>div{
							background: $iceBlue;
							padding: 20px;
							border-radius: 10px;
							@include transition(all, 300ms, ease-in-out);
							&:hover{
								background: $white;
								@include transition(all, 300ms, ease-in-out);
							}
							img{
								border-radius: 10px;
								margin-bottom: 40px;
								min-height: 220px;
								object-fit: cover;
							}
							h3{
								strong{
									color: $teal;
								}
							}
						}
					}
				}
			}
		}
		&.left-image-right-content-type{
			padding: 75px 0;
			&.green-color{
				background: $iceBlue;
			}
			&.small-image{
				>div{
					>div{
						>div{
							&:first-child{
								width: 30%;
							}
							&:last-child{
								width: 70%;
							}
						}
					}
				}
			}
			>div{
				>div{
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					@include align-center;
					>div{
						width: 50%;
						&:first-child{
							img{
								border-radius: 10px;
							}
							.video-wrapper{
								p{
									margin: 0;
								}
								>div{
									position: relative;
									padding-bottom: 56.25%;
									overflow: hidden;
									border-radius: 10px;
									iframe{
										overflow: hidden;
										border: 0;
										align-self: center;
										position: absolute;
										top: 0;
										left: 0;
										width: 100%;
										height: 100%;
										border-radius: 10px;
									}
								}
							}
						}
						&:last-child{
							padding-left: 80px;
							p{
								strong{
									color: $teal;
								}
							}
						}
					}
				}
			}
		}
		&.right-image-left-content-type{
			padding: 75px 0;
			&.green-color{
				background: $iceBlue;
			}
			&.small-image{
				>div{
					>div{
						>div{
							&:first-child{
								width: 70%;
							}
							&:last-child{
								width: 30%;
							}
						}
					}
				}
			}
			>div{
				>div{
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					@include align-center;
					>div{
						width: 50%;
						&:first-child{
							padding-right: 80px;
							p{
								strong{
									color: $teal;
								}
							}
						}
						&:last-child{
							img{
								border-radius: 10px;
							}
							.video-wrapper{
								p{
									margin: 0;
								}
								>div{
									position: relative;
									padding-bottom: 56.25%;
									overflow: hidden;
									border-radius: 10px;
									iframe{
										overflow: hidden;
										border: 0;
										align-self: center;
										position: absolute;
										top: 0;
										left: 0;
										width: 100%;
										height: 100%;
										border-radius: 10px;
									}
								}
							}
						}
					}
				}
			}
		}
		&.row-icon-widgets-type{
			padding: 80px 0;
			>div{
				>div{
					&:first-child{
						text-align: center;
						margin-bottom: 40px;
					}
					&:last-child{
						display: flex;
						flex-direction: row;
						flex-wrap: wrap;
						justify-content: center;
						>div{
							text-align: center;
							margin-bottom: 40px;
							img{
								margin-bottom: 40px;
								height: 100px;
								width: 100px;
							}
							h3, h4{
								strong{
									color: $teal;
								}
							}
						}
					}
				}
			}
		}
		&.split-blog-type{
			margin: 50px auto;
			padding: 80px 0;
			background: $iceBlue;
			>div{
				>div{
					&:first-child{
					    h2{
					        text-align: center;
					        em{
					            color: $teal;
					            font-style: normal;
					        }
					    }
					}
					&:last-child{
						display: flex;
						flex-direction: row;
						flex-wrap: wrap;
			            >div{
			                width: 49%;
			                &:nth-of-type(2n){
			                    margin-left: 2%;
			                }
			                &.blog-list-item{
			                    display: flex;
			                    flex-direction: row;
			                    flex-wrap: wrap;
			                    @include align-center;
			                    margin-bottom: 15px;
			                    padding: 25px;
			                    border-radius: 10px;
			                    background: $iceBlue;
			                    @include transition(all, 300ms, ease-in-out);
			                    &:hover{
			                        background: $white;
			                        @include transition(all, 300ms, ease-in-out);
			                    }
			                    >div{
			                        &:first-child{
			                            width: 200px;
			                            img{
			                                border-radius: 10px;
			                            }
			                        }
			                        &:last-child{
			                            width: calc(100% - 200px);
			                            padding-left: 30px;
			                            h3{
			                                margin-bottom: 10px;
			                            }
										a{
											color: $black;
											&:hover{
												color: $teal;
											}
										}
			                            .blog-list-details{
			                                margin-bottom: 10px;
			                                span{
			                                    display: inline-block;
			                                    margin: 0 5px;
			                                    position: relative;
			                                    top: -1px;
			                                    color: $black;
												&.sep{
													margin: 0;
												}
			                                }
											div{
												display: inline;
												a{
													&:last-child{
														span{
															display: none;
														}
													}
												}
											}
			                                p{
			                                    margin-bottom: 0;
			                                    color: $black;
												display: inline-block;
			                                }
											a{
												color: $teal;
											}
			                            }
			                            .button{
			                                border-radius: 20px;
			                                background-color: $white;
			                                padding: 10px 20px;
			                                margin-top: 10px;
			                            }
			                        }
			                    }
			                }
			            }
				    }
			    }
		    }
		}
		&.split-call-to-action-type{
			padding: 50px 0;
			>div{
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				@include align-center;
				>div{
					width: 48%;
					&:nth-of-type(2n){
						margin-left: 4%;
					}
					&.no-image{
						background: $teal;
						border-radius: 10px;
						position: relative;
						text-align: center;
						padding: 60px 40px;
						display: inline-block;
						min-height: 350px;
						&:after{
				            position: absolute;
				            content: '';
				            right: 0px;
				            bottom: 0;
				            background-image: url('../images/footer-feather.svg');
				            background-size: cover;
				            background-position: top center;
				            width: 300px;
				            height: 250px;
				            opacity: 0.1;
							z-index: 0;
				        }
						>div{
							position: absolute;
							left: 20px;
							width: calc(100% - 40px);
							right: 0;
							top: 50%;
							transform: translateY(-50%);
							z-index: 100;
						}
				        h1, h2, h3,h4, h5, h6, p{
					        color: $white;
					        position: relative;
					        z-index: 100;
				        }
						a:not(.button){
							color: $white;
						}
				        .button{
					        position: relative;
					        z-index: 100;
					        margin-top: 20px;
                            &:hover{
                                border: 1px solid $teal;
                            }
				        }
					}
					&.image{
						border-radius: 10px;
						position: relative;
						text-align: center;
						padding: 60px 40px;
						background-size: cover;
						background-position: 50%;
						display: inline-block;
						min-height: 350px;
						&:after{
				            position: absolute;
				            content: '';
				            top: 0;
				            left: 0;
				            border-radius: 10px;
				            display: inline-block;
				            background: rgba(23, 36, 42, 0.5);
				            width: 100%;
				            height: 100%;
				        }
						>div{
							position: absolute;
							left: 20px;
							width: calc(100% - 40px);
							right: 0;
							top: 50%;
							transform: translateY(-50%);
							z-index: 100;
						}
				        h1, h2, h3,h4, h5, h6, p{
					        color: $white;
					        position: relative;
					        z-index: 100;
				        }
				        .button{
					        position: relative;
					        z-index: 100;
					        margin-top: 20px;
				        }
					}
				}
			}
		}
		&.split-content-type{
			padding: 80px 0;
			>div:last-child{
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: center;
				>div{
					width: 48%;
					&:nth-of-type(2n){
						margin-left: 4%;
					}
				}
			}
		}
		&.split-header-type{
			padding: 100px 0 0;
			background: $iceBlue;
			margin-bottom: 100px;
			>div{
				>div{
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					>div{
						width: 50%;
						&:first-child{
							padding: 30px 70px 0 0;
						}
						&:last-child{
							img{
								border-radius: 10px;
								position: relative;
								top: 50px;
								margin-top: -50px;
							}
						}
					}
				}
			}
		}
		&.table-columns-type{
			padding: 50px 0;
			.table-header, .table-row{
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: center;
				>div{
					display: flex;
					flex-direction: column;
					text-align: center;
					width: calc(33.3333% - 7px);
					&:nth-of-type(3n-1){
						margin-left: 10px;
						margin-right: 10px;
					}
				}
			}
			.table-header{
				>div{
					background: $teal;
					padding: 10px 20px;
					margin-bottom: 10px;
					&:first-child{
						border-top-left-radius: 20px;
					}
					&:last-child{
						border-top-right-radius: 20px;
					}
					h3{
						margin-bottom: 0;
						color: $white;
						position: relative;
						top: 50%;
						transform: translateY(-50%);
					}
				}
			}
			.table-row{
				&:last-child{
					>div{
						&:first-child{
							border-bottom-left-radius: 20px;
						}
						&:last-child{
							border-bottom-right-radius: 20px;
						}
					}
				}
				>div{
					background: $iceBlue;
					padding: 10px 20px;
					margin-bottom: 10px;
					display: flex;
					flex-direction: column;
					p{
						margin-bottom: 0;
					}
				}
			}
		}
		&.team-type{
			padding: 130px 0;
			>div{
				&:first-child{
					text-align: center;
					margin-bottom: 90px;
				}
				&:last-child{
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					>div{
						margin-bottom: 10px;
					}
					.team-biography-toggle{
						color: $black;
						&.active, &:hover{
							.team-details{
								background: rgba(58, 175, 169, 0.2);
								@include transition(all, 300ms, ease-in-out);
							}
						}
					}
					.team-details{
						border-radius: 10px;
						background: $iceBlue;
						padding: 20px;
						min-height: 400px;
						@include transition(all, 300ms, ease-in-out);
						img{
							border-radius: 10px;
							margin-bottom: 20px;
						}
						h3,h4{
							margin-bottom: 5px;
							color: $black;
						}
						p{
							margin-bottom: 10px;
							color: $teal;
						}
					}
					.team-biography{
						max-height: 0;
	                    overflow: hidden;
	                    transition: all 0.5s ease-out;
	                    padding: 0 50px;
	                    margin-top: 50px;
	                    background: $white;
	                    position: relative;
	                    &.active{
		                    max-height: 800px;
	                        transition: all 0.5s ease-in;
	                    }
					}
				}
			}
		}
		&.testimonials-type{
			background: rgba(251, 176, 59, 0.15);
			padding: 120px 0;
			margin: 80px auto;
			.swiper-slide{
				padding: 30px 0 0;
				>div{
					padding: 60px 0 50px;
					border-bottom: 2px solid $teal;
					position: relative;
					&:before{
						position: absolute;
						content: '';
						width: calc(50% - 50px);
						top: 0;
						left: 0;
						height: 2px;
						background: $teal;
					}
					&:after{
						position: absolute;
						content: '';
						width: calc(50% - 50px);
						top: 0;
						right: 0;
						height: 2px;
						background: $teal;
					}
					>div{
						text-align: center;
						position: relative;
						&:before{
							position: absolute;
							content: '\e909';
							font-family: $iconFont;
							color: $teal;
							top: -95px;
							font-size: 50px;
							left: 0;
							right: 0;
							display: inline-block;
							margin: auto;
							text-align: center;
						}
						h5{
							margin-bottom: 30px;
						}
						p{
							&:last-child{
								margin-bottom: 0;
							}
						}
					}
				}
			}
			.swiper-button-prev{
				width: 40px;
				height: 40px;
				border-radius: 50%;
				background: $teal;
				left: 0px;
				transform: translateY(-50%);
				top: 50%;
				&:hover{
					background: $teal;
				}
				&:after{
					content: '\e901';
					font-family: $iconFont;
					color: $white;
					display: inline-block;
					@include rotate(180deg);
					font-size: 	12px;
				}
			}
			.swiper-button-next{
				width: 40px;
				height: 40px;
				border-radius: 50%;
				background: $teal;
				right: 0px;
				transform: translateY(-50%);
				top: 50%;
				&:hover{
					background: $teal;
				}
				&:after{
					content: '\e901';
					font-family: $iconFont;
					color: $white;
					font-size: 14px;
				}
			}
			.swiper-pagination{
				position: relative;
				margin-top: 50px;
				.swiper-pagination-bullet{
					border-radius: 50%;
					border: 1px solid $teal;
					background: transparent;
					width: 12px;
					height: 12px;
					opacity: 1;
					@include transition(all, 300ms, ease-in-out);
					&:hover, &.swiper-pagination-bullet-active{
						background: $teal;
						@include transition(all, 300ms, ease-in-out);
					}
				}
			}
		}
		&.three-column-content-type{
			padding: 40px 0;
			>div{
				&:last-child{
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
				}
			}
		}
		&.video-type{
			padding: 40px 0;
			.video-wrapper{
				p{
					margin: 0;
				}
				>div{
					position: relative;
					padding-bottom: 56.25%;
					overflow: hidden;
					border-radius: 10px;
					iframe{
						overflow: hidden;
						border: 0;
						align-self: center;
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						border-radius: 10px;
					}
				}
			}
		}
		&.no-padding{
			padding-top: 0 !important;
			padding-bottom: 0 !important;
		}
		&.no-margin{
			margin-top: 0 !important;
			margin-bottom: 0 !important;
		}
		h2{
			strong{
				font-weight: bold;
				color: $teal;
			}
		}
	}
}

@media screen and (max-width: 1210px){
	#page-builder-container{
		.content-row{
			&.blog-full-type{
				padding: 80px 20px;
			}
			&.call-to-action-type{
				padding: 20px;
			}
			&.card-widgets-type{
				padding: 80px 20px;
			}
			&.fact-sheet-type{
				padding: 80px 20px 55px;
			}
			&.image-widgets-type{
				padding: 80px 20px;
			}
			&.left-image-right-content-type{
				padding: 75px 20px;
			}
			&.right-image-left-content-type{
				padding: 75px 20px;
			}
			&.row-icon-widgets-type{
				padding: 80px 20px;
			}
			&.split-blog-type{
				padding: 80px 20px;
			}
			&.split-call-to-action-type{
				padding: 50px 20px;
			}
			&.split-header-type{
				padding: 100px 20px 0;
			}
			&.table-columns-type{
				padding: 50px 20px;
			}
			&.team-type{
				padding: 130px 20px;
			}
			&.testimonials-type{
				padding: 120px 20px;
			}
		}
	}
}

@media screen and (max-width: 1200px){
	#page-builder-container{
		.content-row{
			&.split-blog-type{
				>div{
					>div{
						&:last-child{
							>div{
								width: 100%;
								&:not(:last-of-type){
									margin: 0 0 30px;
								}
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1010px){
	#page-builder-container{
		.content-row{
			&.accordions-type{
				padding: 50px 20px;
			}
			&.carousel-type{
				padding: 90px 20px;
			}
			&.content-type{
				padding: 30px 20px;
				&.green-color{
					padding: 60px 20px;
				}
			}
			&.form-type{
				padding: 50px 20px;
			}
			&.header-type{
				padding: 100px 20px 0;
			}
			&.split-content-type{
				padding: 40px 20px;
			}
			&.split-header-type{
				>div{
					>div{
						>div{
							&:first-child, &:last-child{
								width: 100%;
							}
							&:first-child{
								padding: 0 0 30px;
							}
						}
					}
				}
			}
			&.three-column-content-type{
				padding: 40px 20px;
			}
			&.video-type{
				padding: 40px 20px;
			}
		}
	}
}

@media screen and (min-width: 1001px){
	#page-builder-container{
		.content-row{
			&.card-widgets-type{
				>div{
					>div{
						width: 23%;
						&:nth-of-type(4n), &:nth-of-type(4n-1), &:nth-of-type(4n-2){
							margin-left: 2.667%;
						}
					}
				}
			}
			&.team-type{
				>div{
					&:last-child{
						>div{
							width: 23%;
							&:nth-of-type(4n), &:nth-of-type(4n-1), &:nth-of-type(4n-2){
								margin-left: 2.667%;
							}
							&:nth-of-type(4n){
								.team-biography{
									margin-left: -330%;
								}
							}
							&:nth-of-type(4n-1){
								.team-biography{
									margin-left: -222%;
								}
							}
							&:nth-of-type(4n-2){
								.team-biography{
									margin-left: -110%;
								}
							}
							.team-biography{
								width: 434%;
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (min-width: 551px) and (max-width: 1000px){
	#page-builder-container{
		.content-row{
			&.team-type{
				>div{
					&:last-child{
						>div{
							width: 49%;
							&:nth-of-type(2n){
								margin-left: 2%;
								.team-biography{
									margin-left: -103%;
								}
							}
							.team-biography{
								width: 203%;
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1000px){
	#page-builder-container{
		.content-row{
			&.card-widgets-type{
				>div{
					>div{
						width: 100%;
						max-width: 600px;
						display: block;
						margin: 0 auto;
						&:not(:last-of-type){
							margin-bottom: 50px;
						}
					}
				}
			}
		}
	}
}

@media screen and (min-width: 901px){
	#page-builder-container{
		.content-row{
			&.fact-sheet-type{
				>div{
					&:last-child{
						>div{
							width: 32%;
							&:nth-of-type(3n-1){
								margin-left: 2%;
								margin-right: 2%;
							}
						}
					}
				}
			}
			&.image-widgets-type.three-per-row{
				>div{
					>div{
						&:last-child{
							>div{
								width: 32%;
								&:nth-of-type(3n-1){
									margin-left: 2%;
									margin-right: 2%;
								}
							}
						}
					}
				}
			}
			&.row-icon-widgets-type{
				>div{
					>div{
						&:last-child{
							>div{
								width: 32%;
								&:nth-of-type(3n-1){
									margin-left: 2%;
									margin-right: 2%;
								}
							}
						}
					}
				}
			}
			&.three-column-content-type{
				>div{
					&:last-child{
						margin-top: 30px;
						>div{
							width: 31%;
							&:nth-of-type(3n-1){
								margin-left: 3.333%;
								margin-right: 3.333%;
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 900px){
	#page-builder-container{
		.content-row{
			&.left-image-right-content-type{
				&.small-image{
					>div{
						>div{
							>div{
								&:first-child, &:last-child{
									width: 100%;
									padding: 0;
								}
								&:first-child{
									text-align: center;
								}
								&:last-child{
									padding: 40px 0 0;
								}
							}
						}
					}
				}
				>div{
					>div{
						>div{
							&:first-child, &:last-child{
								width: 100%;
								padding: 0;
							}
							&:last-child{
								padding: 40px 0 0;
							}
						}
					}
				}
			}
			&.fact-sheet-type{
				>div{
					&:last-child{
						>div{
							width: 100%;
							max-width: 600px;
							margin: 0 auto;
							display: block;
							&:not(:last-of-type){
								margin-bottom: 40px;
							}
						}
					}
				}
			}
			&.image-widgets-type{
				>div{
					>div{
						&:last-child{
							>div{
								width: 100%;
								max-width: 600px;
								margin: 0 auto;
								display: block;
								&:not(:last-of-type){
									margin-bottom: 40px;
								}
							}
						}
					}
				}
			}
			&.right-image-left-content-type{
				&.small-image{
					>div{
						>div{
							flex-direction: column-reverse;
							>div{
								&:first-child, &:last-child{
									width: 100%;
									padding: 0;
								}
								&:last-child{
									padding: 0 0 40px;
									text-align: center;
								}
							}
						}
					}
				}
				>div{
					>div{
						flex-direction: column-reverse;
						>div{
							&:first-child, &:last-child{
								width: 100%;
								padding: 0;
							}
							&:last-child{
								padding: 0 0 40px;
							}
						}
					}
				}
			}
			&.row-icon-widgets-type{
				>div{
					>div{
						&:last-child{
							>div{
								width: 100%;
								max-width: 600px;
								margin: 0 auto;
								display: block;
								&:not(:last-of-type){
									margin-bottom: 40px;
								}
							}
						}
					}
				}
			}
			&.split-call-to-action-type{
				>div{
					>div{
						width: 100%;
						&:nth-of-type(2n){
							margin: 50px 0 0;
						}
					}
				}
			}
			&.split-content-type{
				>div:last-child{
					>div{
						width: 100%;
						&:nth-of-type(2n){
							margin-left: 0;
						}
					}
				}
			}
			&.table-columns-type{
				overflow-x: scroll;
				>div{
					width: 900px;
				}
			}
		}
	}
}

@media screen and (min-width: 768px){
	#page-builder-container{
		.content-row{
			&.image-widgets-type.two-per-row{
				>div{
					>div{
						&:last-child{
							>div{
								width: 49%;
								&:nth-of-type(2n){
									margin-left: 2%;
								}
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 767px){
	#page-builder-container{
		.content-row{
			&.accordions-type{
				.accordion{
					.accordion-header{
						&:after{
							font-size: 14px;
							top: 29px;
						}
					}
				}
			}
			&.blog-full-type{
				padding: 60px 20px;
				>div{
					>div{
						&:last-child{
							>div{
								&:nth-of-type(2n){
									margin-left: 0;
								}
								&.blog-list-item{
									background: $white;
									margin-bottom: 30px;
									>div{
										&:first-child, &:last-child{
											width: 100%;
											text-align: left;
										}
										&:last-child{
											padding: 30px 0 0;
										}
									}
								}
							}
						}
					}
				}
			}
			&.card-widgets-type{
				padding: 60px 20px;
				>div{
					>div{
						.title{
							min-height: auto;
						}
					}
				}
			}
			&.carousel-type{
				padding: 50px 20px;
				text-align: center;
				.swiper-slide{
					padding: 0;
					>div{
						flex-direction: column-reverse;
						>div{
							&:first-child, &:last-child{
								width: 100%;
								text-align: left;
							}
							&:first-child{
								padding: 30px 0 0;
							}
						}
					}
				}
				.swiper-button-next, .swiper-button-prev{
					position: relative;
					display: inline-block;
					line-height: 47px;
					top: 0;
					transform: none;
					width: 50px;
					height: 50px;
					margin-top: 10px;
				}
				.swiper-button-prev{
					float: left;
				}
				.swiper-button-next{
					float: right;
				}
				.swiper-pagination{
					display: inline;
					padding: 0 10px;
					top: 25px;
				}
			}
			&.header-type{
				padding: 70px 20px 0;
				img{
					height: auto;
				}
			}
			&.image-widgets-type{
				padding: 50px 20px;
				>div{
					>div{
						&:first-child{
							margin-bottom: 20px;
						}
					}
				}
			}
			&.row-icon-widgets-type{
				padding: 60px 20px;
			}
			&.split-blog-type{
				padding: 60px 20px;
				>div{
					>div{
						&:last-child{
							>div{
								&.blog-list-item{
									background: $white;
									>div{
										&:first-child, &:last-child{
											width: 100%;
											text-align: left;
										}
										&:last-child{
											padding: 30px 0 0;
										}
									}
								}
							}
						}
					}
				}
			}
			&.split-header-type{
				padding: 70px 20px 0;
			}
			&.table-columns-type{
				padding: 40px 20px;
			}
			&.team-type{
				padding: 70px 20px;
				>div{
					&:first-child{
						margin-bottom: 40px;
					}
					&:last-child{
						.team-details{
							min-height: auto;
						}
						.team-biography{
							padding: 0;
						}
					}
				}
			}
			&.testimonials-type{
				padding: 80px 20px;
			}
		}
	}
}
/*! purgecss end ignore */