@import url("https://use.typekit.net/ybs5pty.css");

@font-face {
    font-family: 'brownhill-script';
    src: url('..//fonts/brownhill/brownhill_script-webfont.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;

}

$aktiv: 'aktiv-grotesk', sans-serif;
$lemonde: 'le-monde-livre-std';
$brownhill: 'brownhill-script';

@mixin align-center{
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	-moz-align-items: center;
	align-items: center;
	align-content: center;
}

.report-container{
	max-width: 1200px;
	margin: 0 auto;
	display: block;
	position: relative;
	&.medium-container{
		max-width: 960px;
	}
	&.small-container{
		max-width: 720px;
	}
}

#report-header-container{
	padding: 80px 0;
	background-size: cover;
	background-position: 50%;
	position: relative;
	height: 600px;
	&:before{
		position: absolute;
		content: '';
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: rgba(60, 174, 169, 0.5);
		display: inline-block;
	}
	img{
		width: 250px;
		margin-bottom: 60px;
	}
	h1{
		color: $white;
		font-family: $lemonde;
		font-size: 64px;
		line-height: 1.1;
		&:after{
			display: none;
		}
	}
	p{
		color: $white;
		font-style: italic;
		font-size: 20px;
		&.underline{
			position: relative;
			display: inline-block;
			&:before{
				position: absolute;
				content: '';
				width: calc(100% + 300px);
				left: -300px;
				height: 3px;
				background: $dawn;
				bottom: 0;
			}
		}
		em{
			font-family: $brownhill;
			font-size: 40px;
			margin-left: 5px;
		}
	}
	#report-navigation-container{
		position: absolute;
		bottom: 0;
		right: 0;
		left: 0;
		margin: auto;
		text-align: right;
		>div{
			display: inline-block;
			text-align: left;
			background: $lightBlue;
			padding: 40px 30px;
			width: 290px;
			position: relative;
			&:before{
				position: absolute;
				content: '';
			    display: inline-block;
			    width: 0;
			    height: 0;
			    bottom: -60px;
			    left: 0;
			    border-style: solid;
			    border-width: 60px 145px 0 145px;
			    border-color: $lightBlue transparent transparent transparent;
			}
			a{
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				margin-bottom: 5px;
				text-decoration: none;
				opacity: 1;
                cursor: pointer;
				&:hover{
					opacity: 0.7;
				}
				>div{
					&:first-child{
						width: 80%;
					}
					&:last-child{
						width: 20%;
						text-align: right;
					}
				}
				p{
					color: $black;
					font-style: normal;
					letter-spacing: 0;
					font-size: 15px;
				}
			}
		}
	}
}

#grants-container{
	#grants-top{
		padding: 140px 0 80px;
		>div{
			>div{
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				>div{
					width: 50%;
					position: relative;
					&:first-child{
						padding-right: 40px;
						&:after{
							position: absolute;
							content: '';
							width: 1px;
							height: calc(100% + 140px);
							top: 0;
							right: 0;
							background: $gray;
						}
					}
					&:last-child{
						padding-left: 40px;
					}
					p{
						text-align: center;
						&:not(.title){
							font-family: $lemonde;
							font-size: 20px;
							letter-spacing: 0;
							em{
								font-style: normal;
								color: $teal;
								font-weight: 500;
								font-size: 22px;
							}
						}
						&.title{
							font-weight: 600;
							font-size: 24px;
							letter-spacing: 0;
							margin-bottom: 30px;
							text-align: left;
						}
					}
					img{
						margin-bottom: 30px;
					}
				}
			}
		}
	}
	#grants-bottom{
		background: $lightBlue;
		padding: 80px 0;
		>div{
			>div{
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				>div{
					width: 50%;
					text-align: center;
					img{
						width: 60px;
						margin-bottom: 20px;
					}
					h3{
						font-family: $lemonde;
						font-size: 24px;
						letter-spacing: 0;
						text-transform: none;
						font-weight: bold;
                        margin-bottom: 5px;
					}
					h2{
						font-weight: 500;
						font-family: $lemonde;
						font-size: 44px;
                        color: $teal;
					}
					p{
						margin-bottom: 0;
						letter-spacing: 0;
						font-size: 18px;
						line-height: 1.4;
						font-weight: 400;
					}
					.split{
						display: flex;
						flex-direction: row;
						flex-wrap: wrap;
						justify-content: center;
						margin-top: 40px;
						>div{
							padding: 0 10px;
							width: 50%;
						}
					}
				}
			}
		}
	}
}

#impact-container{
	#impact-header{
		position: relative;
		img{
			height: 600px;
			object-fit: cover;
			width: 100%;
		}
		>div{
			position: absolute;
			bottom: 0;
			margin: auto;
			left: 0;
			right: 0;
			padding: 0 0 80px;
			h2{
				font-family: $lemonde;
				font-size: 60px;
				color: $white;
				em{
					font-family: $brownhill;
					font-size: 100px;
				}
			}
		}
	}
	#impact-content{
		padding: 50px 0 0;
		&:before{
			position: absolute;
			content: '';
			width: 2px;
			height: calc(100% + 45px);
			top: -125px;
			background: $dawn;
			left: -60px;
		}
		.impact-content-row{
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			@include align-center;
			margin-bottom: 90px;
			>div{
				&:first-child{
					padding-right: 100px;
					width: 55%;
                    .title{
                        p{
                            text-transform: uppercase;
                            margin-bottom: 15px;
                            strong{
                                font-weight: 600;
                            }
                        }
                    }
					p{
						font-size: 18px;
						line-height: 1.8;
						letter-spacing: 0.5px;
					}
				}
				&:last-child{
					width: 45%;
					.image-wrapper{
						position: relative;
						img{
							&:not(.icon){
								width: 100%;
								height: 300px;
								object-fit: cover;
							}
							&.icon{
								position: absolute;
								bottom: -40px;
								left: -80px;
								border-radius: 50%;
								width: 150px;
								z-index: 10;
							}
						}
						.title{
							background: $teal;
							text-align: right;
							display: inline-block;
							width: 100%;
							padding: 10px;
							position: relative;
							top: -4px;
							p{
								color: $white;
								font-size: 12px;
								font-style: italic;
                                margin-bottom: 0;
							}
						}
					}
				}
			}
		}
	}
}

#legacy-container{
	img{
		height: 700px;
		object-fit: cover;
		width: 100%;
	}
	>div{
		background: $white;
		top: -150px;
		margin-bottom: -100px;
		padding: 80px;
		.title{
			margin-bottom: 30px;
			h2{
				color: $black;
				font-family: $lemonde;
				font-size: 44px;
				font-weight: 500;
				line-height: 1.13;
				em{
					font-family: $brownhill;
					font-size: 80px;
				}
			}
		}
		p{
			font-size: 18px;
			font-weight: 300;
			line-height: 2;
			&.subtitle{
				font-weight: 600;
			}
		}
		a{
			color: $dawn;
			text-decoration: underline;
		}
        .subtitle{
            p{
                font-weight: 600;
            }
        }
        .link{
            p, a{
                color: $dawn;
                font-weight: 600;
            }
        }
	}
}

#downloads-container{
	background: $lightBlue;
	padding: 100px 0;
	>div{
		>div{
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			>div{
				&:first-child{
					width: 140px;
					border-right: 3px solid $teal;
					padding: 10px 0;
				}
				&:last-child{
					padding-left: 30px;
					>div{
						display: flex;
						flex-direction: row;
						flex-wrap: wrap;
						>div{
							width: 100%;
							padding: 10px 0;
							a{
								display: inline-block;
								&:not(:last-of-type){
									margin-right: 30px;
								}
							}
						}
					}
				}
				p{
					font-family: $lemonde;
					font-weight: bold;
					font-size: 14px;
					line-height: 1.2;
					margin-bottom: 15px;
					width: 100%;
					max-width: 140px;
				}
				img{
					width: 30px;
					margin-bottom: 10px;
				}
				span{
					display: block;
					font-size: 10px;
					font-family: $lemonde;
					color: $black;
					font-weight: bold;
				}
			}
		}
	}
}

#about-container{
	text-align: center;
	background: $teal;
	padding: 150px 0;
	img{
		width: 50px;
		margin: 0 auto 60px;
	}
	h2{
		margin-bottom: 40px;
		color: $white;
		font-family: $lemonde;
		font-size: 54px;
		font-weight: normal;
	}
	p{
		color: $white;
		font-family: $aktiv;
		font-size: 18px;
		line-height: 2;
		font-weight: 300;
		strong{
			font-weight: 500;
		}
	}
	a.custom-link{
		color: $dawn;
		text-decoration: none;
		opacity: 1;
		font-weight: 600;
		font-size: 18px;
		display: inline-block;
		margin-top: 30px;
		&:hover{
            color: $dawn;
			opacity: 0.7;
		}
	}
}

@media screen and (max-width: 1350px){
	#impact-container{
		#impact-header{
			>div{
				padding: 0 0 80px 40px;
			}
		}
		#impact-content{
			padding: 50px 20px 0 40px;
			&:before{
				left: 0;
			}
		}
	}
}

@media screen and (max-width: 1280px){
	#legacy-container{
		>div{
			max-width: calc(100% - 40px);
			padding: 80px 40px;
		}
	}
}

@media screen and (max-width: 1240px){
	#report-header-container{
		padding: 80px 20px;
		#report-navigation-container{
			right: 20px;
		}
	}
}

@media screen and (max-width: 1000px){
	#grants-container{
		#grants-top{
			padding: 140px 20px 80px;
		}
		#grants-bottom{
			padding: 80px 20px;
		}
	}
	#impact-container{
		#impact-header{
			>div{
				padding: 0 0 80px 20px;
			}
		}
		#impact-content{
			padding: 50px 20px 0;
			&:before{
				display: none;
			}
			.impact-content-row{
				flex-direction: column-reverse;
				margin-bottom: 50px;
				>div{
					&:first-child, &:last-child{
						width: 100%;
					}
					&:first-child{
						padding: 80px 0 0;
					}
					&:last-child{
						.image-wrapper{
							img{
								&:not(.icon){
									height: 400px;
								}
								&.icon{
									left: 0;
									right: 0;
									margin: auto;
								}
							}
						}
					}
				}
			}
		}
	}
	#legacy-container{
		>div{
			.title{
				h2{
					em{
						font-size: 53px;
					}
				}
			}
		}
	}
	#downloads-container{
		padding: 100px 20px;
	}
	#about-container{
		padding: 150px 20px;
	}
}

@media screen and (max-width: 850px){
	#legacy-container{
		>div{
			.title{
				h2{
					em{
						display: block;
						font-size: 60px;
						margin-top: 50px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 767px){
	#report-header-container{
		height: auto;
		padding: 80px 20px 340px;
		img{
			width: 200px;
			margin-bottom: 40px;
		}
		h1{
			font-size: 50px;
		}
		p{
			font-size: 18px;
			em{
				font-size: 30px;
			}
		}
		#report-navigation-container{
			text-align: center;
			bottom: -100px;
			right: 0;
		}
	}
	#grants-container{
		#grants-top{
			padding: 180px 20px 60px;
			>div{
				>div{
					>div{
						width: 100%;
						&:first-child{
							padding: 0;
							&:after{
								display: none;
							}
						}
						&:last-child{
							padding: 0;
							margin-top: 60px;
						}
						p{
							&.title{
								font-size: 22px;
							}
							&:not(.title){
								font-size: 18px;
								em{
									font-size: 20px;
								}
							}
						}
					}
				}
			}
		}
		#grants-bottom{
			padding: 60px 20px;
			>div{
				>div{
					>div{
						width: 100%;
						&:last-child{
							margin-top: 60px;
						}
                        .split{
                            >div{
                                width: 100%;
                                padding: 10px 0;
                            }
                        }
						h3{
							font-size: 20px;
						}
						h2{
							font-size: 34px;
						}
						p{
							font-size: 16px;
						}
					}
				}
			}
		}
	}
	#impact-container{
		#impact-header{
			img{
				height: 400px;
			}
			>div{
				padding: 0 20px 40px;
				h2{
					font-size: 40px;
					line-height: 1.2;
					em{
						font-size: 45px;
					}
				}
			}
		}
		#impact-content{
			.impact-content-row{
				>div{
					&:first-child{
						padding: 30px 0 0;
						p{
							font-size: 16px;
						}
					}
					&:last-child{
						.image-wrapper{
							img{
								&:not(.icon){
									height: 250px;
								}
								&.icon{
									display: none;
								}
							}
						}
					}
				}
			}
		}
	}
	#legacy-container{
		img{
			height: 250px;
		}
		>div{
			max-width: 100%;
			top: 0;
			margin-bottom: 0;
			padding: 50px 20px;
			.title{
				h2{
					font-size: 34px;
					em{
						font-size: 50px;
						margin-top: 30px;
                        display: inline;
					}
				}
			}
			p{
				font-size: 16px;
			}
		}
	}
	#downloads-container{
		padding: 60px 20px;
		>div{
			>div{
				>div{
					&:first-child{
						width: 100%;
						border-right: 0;
						border-bottom: 2px solid $teal;
						padding: 0 0 20px;
					}
					&:last-child{
						padding: 20px 0 0;
                        a{
                            margin-bottom: 20px;
                        }
					}
				}
			}
		}
	}
	#about-container{
		padding: 100px 20px;
		h2{
			font-size: 38px;
			margin-bottom: 40px;
		}
		p, a{
			font-size: 16px;
		}
		a{
			margin-top: 30px;
		}
	}
}