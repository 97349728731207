#generosity-header-container{
    background-color: $iceBlue;
    padding: 100px 0;
    img{
        position: relative;
        top: 200px;
        margin-top: -150px;
        height: 460px;
        border-radius: 10px;
        object-fit: cover;
        width: 100%;
    }
}

#generosity-calculator-introduction{
    padding: 180px 0 100px;
    text-align: center;
    h2{
        strong{
            color: $teal;
        }
    }
}

#generosity-calculator-video{
    >div{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        @include align-center;
        >div{
            width: 48%;
            &:nth-of-type(2n){
                margin-left: 4%;
                p{
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
            .video-wrapper{
				p{
					margin: 0;
				}
				>div{
					position: relative;
					padding-bottom: 56.25%;
					overflow: hidden;
					border-radius: 10px;
					iframe{
						overflow: hidden;
						border: 0;
						align-self: center;
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						border-radius: 10px;
					}
				}
			}
        }
    }
}

#generosity-calculator{
    #generosity-calculator-form{
        margin: 80px auto;            
        background-color: $iceBlue;
        border-radius: 10px;
        padding: 30px 50px;
        #generosity-calculator-inputs{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            @include align-center;
            >div{
                width: 20%;
                padding: 0 10px;
                >div{
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    >div{
                        &:first-child{
                            width: calc(100% - 20px);
                        }
                        &:last-child{
                            width: 20px;
                            a{
                                border-radius: 50%;
                                background: $teal;
                                border-radius: 50%;
                                width: 20px;
                                height: 20px;
                                color: $white;
                                display: inline-block;
                                text-align: center;
                                font-size: 14px;
                                cursor: pointer;
                                outline: thin dotted $black;
                                &:focus{
                                    border: 1px solid rgba(43, 122, 120, 0.8);
                                    @include transition(all, 300ms, ease-in-out);
                                }
                                &:hover{
                                    opacity: 0.5;
                                }
                            }
                        }
                    }
                }
            }
        }
        #generosity-calculator-button{
            padding: 50px 0 0;
            text-align: center;
            button[type="submit"]{
                padding: 15px 20px;
                &:disabled{
                    background: $gray;
                    cursor: default;
                }
            }
        }
    }
    #generosity-calculator-results{
        padding: 0 0 50px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        >div{
            width: 49%;
            display: flex;
            flex-direction: column;
            &:nth-of-type(2n){
                margin-left: 2%;
            }
            h3{
                text-align: center;
                margin-bottom: 50px;
            }
            canvas{
                margin-bottom: 50px;
            }
            .graph-legend{
                margin-top: auto;
                .graph-legend-header{
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    >div{
                        text-align: center;
                        padding: 5px 10px;
                        &:nth-child(1){
                            width: 40%;
                        }
                        &:nth-child(2){
                            width: 30%;
                            border-top: 1px solid rgba(23,37,42,0.25);
                            border-right: 1px solid rgba(23,37,42,0.25);
                            border-left: 1px solid rgba(23,37,42,0.25);
                        }
                        &:nth-child(3){
                            width: 30%;
                            border-top: 1px solid rgba(23,37,42,0.25);
                            border-right: 1px solid rgba(23,37,42,0.25);
                        }
                        p{
                            font-weight: bold;
                            font-size: 10px;
                            margin-bottom: 0;
                        }
                        a{
                            background: $dawn;
                            width: 12px;
                            height: 12px;
                            border-radius: 50%;
                            font-size: 9px;
                            line-height: 12px;
                            color: $white;
                            display: inline-block;
                            margin-left: 5px;
                        }
                    }
                }
                .graph-legend-row{
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    &:nth-child(2){
                        >div{
                            &:nth-child(1){
                                span{
                                    background-color: rgba(218, 218, 218, 0.85);
                                }
                            }
                        }
                    }
                    &:nth-child(3){
                        >div{
                            &:nth-child(1){
                                span{
                                    background-color: rgba(43, 122, 120, 0.85);
                                }
                            }
                        }
                    }
                    &:last-child{
                        >div{
                            &:nth-child(1){
                                border-bottom: 1px solid rgba(23,37,42,0.25);
                            }
                            &:nth-child(2){
                                border-bottom: 1px solid rgba(23,37,42,0.25);
                            }
                            &:nth-child(3){
                                border-bottom: 1px solid rgba(23,37,42,0.25);
                            }
                        }
                    }
                    >div{
                        text-align: center;
                        padding: 5px 10px;
                        &:nth-child(1){
                            width: 40%;
                            border-top: 1px solid rgba(23,37,42,0.25);
                            border-right: 1px solid rgba(23,37,42,0.25);
                            border-left: 1px solid rgba(23,37,42,0.25);
                            p{
                                font-weight: bold;
                                display: inline-block;
                                vertical-align: middle;
                            }
                            span{
                                width: 10px;
                                height: 10px;
                                margin-right: 5px;
                                display: inline-block;
                                vertical-align: middle;
                            }
                        }
                        &:nth-child(2){
                            width: 30%;
                            border-top: 1px solid rgba(23,37,42,0.25);
                            border-right: 1px solid rgba(23,37,42,0.25);
                        }
                        &:nth-child(3){
                            width: 30%;
                            border-top: 1px solid rgba(23,37,42,0.25);
                            border-right: 1px solid rgba(23,37,42,0.25);
                        }
                        p{
                            font-size: 12px;
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
    #graph-disclaimer{
        padding: 0 60px 80px;
        p{
            font-size: 12px;
        }
    }
}

#generosity-call-to-action-container{
    background: $teal;
    border-radius: 10px;
    position: relative;
    text-align: center;
    padding: 120px 40px;
    margin-bottom: 80px;
    &:after{
        position: absolute;
        content: '';
        right: 0px;
        bottom: 0;
        background-image: url('../images/footer-feather.svg');
        background-size: cover;
        background-position: top center;
        width: 300px;
        height: 250px;
        opacity: 0.1;
    }
    h1, h2, h3,h4, h5, h6, p{
        color: $white;
        position: relative;
        z-index: 100;
    }
    a:not(.button){
        color: $white;
    }
    .button{
        position: relative;
        z-index: 100;
        margin-top: 20px;
        &:hover{
            border: 1px solid $teal;
        }
    }
}

@media screen and (max-width: 1210px){
    #generosity-header-container{
        padding: 100px 20px;
    }
    #generosity-calculator-video{
        padding: 0 20px;
    }
    #generosity-calculator-container{
        padding: 0 20px;
    }
    #generosity-call-to-action-container{
        max-width: calc(100% - 40px);
    }
}

@media screen and (max-width: 1010px){
    #generosity-calculator-introduction{
        padding: 180px 20px 100px;
    }
    #generosity-calculator-video{
        >div{
            >div{
                width: 100%;
                &:nth-of-type(2n){
                    margin-left: 0;
                    padding: 30px 0 0;
                }
            }
        }
    }
    #generosity-calculator{
        #generosity-calculator-form{
            padding: 30px 20px;
            #generosity-calculator-inputs{
                >div{
                    width: 33.3333%;
                    padding: 10px;
                }
            }
        }
    }
}

@media screen and (max-width: 850px){
    #generosity-calculator{
        #generosity-calculator-results{
            >div{
                width: 100%;
                &:nth-of-type(2n){
                    margin-top: 50px;
                }
            }
        }
    }
}

@media screen and (max-width: 767px){
    #generosity-header-container{
        padding: 60px 20px 100px;
        img{
            height: auto;
        }
    }
    #generosity-calculator-introduction{
        padding: 140px 20px 80px;
    }
    #generosity-calculator{
        #generosity-calculator-form{
            margin: 40px auto;
            #generosity-calculator-inputs{
                >div{
                    width: 100%;
                }
            }
        }
        #generosity-calculator-results{
            > div{
                .graph-legend{
                    .graph-legend-header{
                        >div{
                            p{
                                font-size: 8px;
                            }
                        }
                    }
                    .graph-legend-row{
                        > div{
                            p{
                                font-size: 10px;
                            }
                        }
                    }
                }
            }
        }
        #graph-disclaimer{
            padding: 0 0 50px;
        }
    }
    #generosity-call-to-action-container{
        padding: 80px 20px;
    }
}